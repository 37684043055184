import * as React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import { Link } from "gatsby"
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import gcg from "../images/gcg.png"
import interview1 from "../images/stills/interview1.jpg"
import interview3 from "../images/stills/interview3.jpg"
import interview4 from "../images/stills/interview4.jpg"
import interview8 from "../images/stills/interview8.jpg"

import bubble2 from "../images/stills/bubble2.jpg"
import bubble3 from "../images/stills/bubble3.jpg"

import protest1 from "../images/stills/protest1.jpg"
import protest2 from "../images/stills/protest2.jpg"
import protest3 from "../images/stills/protest3.jpg"

import still1 from "../images/stills/still1.jpg"
import still2 from "../images/stills/still2.jpg"
import still3 from "../images/stills/still3.jpg"
import still4 from "../images/stills/still4.jpg"
import still5 from "../images/stills/still5.jpg"
import still6 from "../images/stills/still6.jpg"

import cj from "../images/stills/cj.jpg"

import { useEffect } from 'react';




// markup
const Synopsis = () => {
  useEffect(() => {
    // 👇️ adding multiple classes to body tag
    document.body.classList.add(
      'd-flex',
      'h-100',
      'text-center',
      'text-white',
      'bg-dark'
    );
  }, []);
  return (
    <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
      <GatsbySeo
		      title='Game Change Game Movie'
		      description='Game Change Game is a four part series that takes viewers inside a basketball season like no other; one that found the players navigating circumstances of unforeseen sacrifice, loss and their own morality. As a social justice reckoning played out across the globe, sitting on the bench was not an option. We see the drama unfold in real time, as the players union contemplates their response to wave after wave of police brutality. We focus on the executive leadership and key players, guiding them towards a singular voice and collective action. We know this moment will change basketball forever, but will it create lasting change outside of the arena?'
		    />
      <header className="mb-auto">
        <div>
          <nav className="nav nav-masthead justify-content-center ">
            <Link to="/" className="nav-link fw-bold py-1 px-0 ">Home</Link>
            <Link to="/synopsis" className="nav-link fw-bold py-1 px-0 ">Synopsis</Link>
            <Link to="/trailer" className="nav-link fw-bold py-1 px-0 ">Trailer</Link>
            <Link to="/contact" className="nav-link fw-bold py-1 px-0 ">Contact</Link>
          </nav>
        </div>
      </header>


      <main class="px-3">
        <img src={gcg} alt="Tribeca Film Festival 2022 Official Selection" className="img-fluid synopsiscard"  />
        <p><em>Game Change Game</em> takes viewers inside a basketball season like no other; one that finds the players navigating circumstances of unforeseen sacrifice, loss and their own morality. As a social justice reckoning played out across the globe, sitting on the bench was not an option. We see the drama unfold in real time, as the players union contemplates their response to wave after wave of police brutality. Unmatched access to players and coaches reveals never before seen perspectives, including first-person footage shot by the players themselves. </p>

          <div className="row">
          <div className="col-md-4">
            <img src={protest2} alt="" className="img-fluid stills" />
          </div>
          <div className="col-md-4">
            <img src={protest3} alt="" className="img-fluid stills" />
          </div>
          <div className="col-md-4">
            <img src={protest1} alt="" className="img-fluid stills" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={bubble2} alt="" className="img-fluid stills" />
          </div>
          <div className="col-md-6">
            <img src={bubble3} alt="" className="img-fluid stills" />
          </div>
        </div>
        <p>Additional interviews with players, union leadership, journalists, recording artists, activists and victims of police brutality, contextualized with scenes of activism broaden the scope and ground us in this exceptional moment to blend sports, pop-culture and humanity, creating a statement that is inclusive, timely and necessary.</p>
        <img src={cj} alt="" className="img-fluid stills" />
        <div className="row">
          <div className="col-md-6">
            <img src={interview1} alt="" className="img-fluid stills" />
          </div>
          <div className="col-md-6">
            <img src={interview3} alt="" className="img-fluid stills" />
          </div>
          <div className="col-md-6">
            <img src={interview4} alt="" className="img-fluid stills" />
          </div>
          <div className="col-md-6">
            <img src={interview8} alt="" className="img-fluid stills" />
          </div>
        </div>
        <p>We know this moment will change basketball forever, but will it create lasting change outside of the arena?</p>
        <div className="row">
          <div className="col-md-4">
            <img src={still1} alt="" className="img-fluid stills" />
          </div>
          <div className="col-md-4">
            <img src={still2} alt="" className="img-fluid stills" />
          </div>
          <div className="col-md-4">
            <img src={still3} alt="" className="img-fluid stills" />
          </div>
        </div>
          <div className="row">
          <div className="col-md-4">
            <img src={still4} alt="" className="img-fluid stills" />
          </div>
          <div className="col-md-4">
            <img src={still5} alt="" className="img-fluid stills" />
          </div>
          <div className="col-md-4">
            <img src={still6} alt="" className="img-fluid stills" />
          </div>
        </div>
        <p>Featuring: CJ McCollum, Chris Paul, Matisse Thybulle, JaVale McGee Udonis Haslem, Jaylen Brown, Andre Iguodala, Malcom Brogdon, Danny Green, Tobias Harris, Doc Rivers, Julius Erving, Killer Mike, Nikole Hannah-Jones, Marc Spears.</p>

      </main>

      <footer className="mt-auto">
        {/* <a href="https://tribecafilm.com/films/game-change-game-2022" className="nav-link fw-bold py-1 px-0 " target="_blank"><img src={tribeca} alt="Tribeca Film Festival 2022 Official Selection" className="img-fluid tribeca" /></a>
        <img src={sitting} alt="Tribeca Film Festival 2022 Official Selection" className="img-fluid sitting" />
        <img src={billing} alt="Tribeca Film Festival 2022 Official Selection" className="img-fluid billing" /> */}
      </footer>

    </div>
  )
}

export default Synopsis
